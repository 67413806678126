import { Spin } from "antd"
import { FC } from "react"

export const Loader:FC = () => {
    return <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
    }}>
        <Spin size="large" tip={'Loading...'} />
    </div>
}


