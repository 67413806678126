import { createAction } from '@reduxjs/toolkit'
import {PreOrderItem} from './initialState'


export const PRE_ORDER_PRODUCT_LIST_REQ = 'PRE_ORDER_PRODUCT_LIST_REQ';
export const preOrderProductListReq = createAction(PRE_ORDER_PRODUCT_LIST_REQ);

export const PRE_ORDER_PRODUCT_LIST_REQ_COMPLETE = 'PRE_ORDER_PRODUCT_LIST_REQ_COMPLETE';
export const preOrderProductListReqComplete = createAction<{products: {label: string, value: string}[]}>(PRE_ORDER_PRODUCT_LIST_REQ_COMPLETE);


export const PRE_ORDER_ADD_ITEM = 'PRE_ORDER_ADD_ITEM';
export const preOrderAddItem = createAction<{orderItem: PreOrderItem}>(PRE_ORDER_ADD_ITEM);
