import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../rootReducer'
import { initialState } from './initialState';

const productsSelector = (state: Store) => state.products || initialState;

export const productsInprogressSelector = createSelector(
    productsSelector,
    productsState => productsState.loading
);

export const allProductsSelector = createSelector(
    productsSelector,
    productsState => productsState.products
);

// Placeholder search for now, later will be handled via API .
export const productsSearchSelector = (search: string | null) => createSelector(
    productsSelector,
    productsState => {
        if (search && search.length) {
            return productsState.products.filter(
                (product) => product.title.toLowerCase().includes(search.trim().toLowerCase()))
        }
        return productsState.products
    }
)

export const productCartValueSelector = (productId: string) => createSelector(
    productsSelector,
    productsState => {
        const totalQuantity = productsState.cartItems
                                .filter(item => item.productId === productId)
                                .reduce((total, item) => total + item.quantity, 0);
        return totalQuantity || null}
)

export const allProductsInCartSelector = createSelector(
    productsSelector,
    productsState => productsState.cartItems
)

export const inCartProductsCount = createSelector(
    productsSelector,
    productsState => productsState.cartItems.length
)

export const showEditCreateModalSelector = createSelector(
    productsSelector,
    productsState => productsState.showEditCreateModal
);

export const currentEditProductSelector = createSelector(
    productsSelector,
    productsState => productsState.currentProduct
);


export const productSubmitLoading = createSelector (
    productsSelector,
    productsState => productsState.submitLoading
);

export const paymentDetailsSelector = createSelector (
    productsSelector,
    productsState =>  {
        const subtotal = productsState.cartItems.reduce((a, b) => (b.quantity*b.unitPrice)+a, 0)
        const discountAmount = productsState.cartItems.reduce((a, b) => (b.quantity*(b.discountAmount))+a, 0)
        const totalAmount = productsState.cartItems.reduce((a, b) => (b.quantity*(b.unitPrice-b.discountAmount))+a, 0)
        return {subtotal, discountAmount, totalAmount}
    }
);