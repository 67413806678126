import { FC, useState } from "react";
import { Modal, Form, Input, InputNumber, DatePicker, TimePicker, Row, Col, FormInstance } from "antd";
import PhoneNumberPrefix from "../../../components/phoneNumberPrefix";

interface IProps {
    formRef: FormInstance<any>;
    onSubmit: (values: any) => void;
}

const OrderConfirmation: FC<IProps> = ({ formRef, onSubmit }) => {




    return <>
        <Form form={formRef}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
        >
            <Form.Item label="Customer Name" name="customerName"
                rules={[{ required: true, message: 'Customer name is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="phone"
                label="Contact Number"
                rules={[{ required: true, message: 'Contact number is required' }]}>
                <InputNumber addonBefore={<PhoneNumberPrefix />} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="address"
                label="Address" >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                name="delivery_at"
                label="Delivery at">
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="delivery_date"
                            label="Date"
                            rules={[{ required: true, message: 'Delivery date is required' }]}>
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="delivery_time"
                            label="Time"
                            rules={[{ required: true, message: 'Delivery time is required' }]}>
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>


            <Form.Item
                name="advance_payed"
                label="Advance payed"
                rules={[{ required: true, message: 'Input advance amount payed' }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item
                name="remarks"
                label="Remarks"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    </>
}

export default OrderConfirmation;