import {  createReducer } from '@reduxjs/toolkit';
import {createInvoice, createInvoiceComplete, getInvoicesRequest, getInvoicesComplete, getInvoiceComplete, getInvoice, dateRangeChange} from './actions';
import { initialState } from './initialState';

const invoiceReducer = createReducer(initialState, (builder) => {
    builder.addCase(createInvoice, (state, action) => {
        state.createInProgress = true;
    })
    .addCase(createInvoiceComplete, (state, action) => {
        state.createInProgress = false;
    })
    .addCase(getInvoicesRequest, (state, action) => {
        state.inProgress = true;
    })
    .addCase(getInvoicesComplete, (state, action) => {
        state.inProgress = false;
        state.invoices = action.payload.invoices;
        state.invoiceLastItem = action.payload.lastVisible;
        state.invoicesCount = action.payload.count;
    })
    .addCase(getInvoice, (state, action) => {
        state.inProgress = true;
    })
    .addCase(getInvoiceComplete, (state, action) => {
        state.inProgress = false
        const {invoiceItems, invoiceDetail} = action.payload;
        if (invoiceDetail) state.invoiceDetails = {invoiceItems: invoiceItems, invoiceDetail}
    })
    .addCase(dateRangeChange, (state, action) => {
        state.dateRange = action.payload
    })
})


export default invoiceReducer;