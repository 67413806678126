import React from 'react';
import 'antd/dist/reset.css';
import { Provider } from 'react-redux'
import store from './store/store'
import Intial from './pages/initial';
import {BarcodeScanner} from 'react-usb-barcode-scanner';



const DemoBox: React.FC<{ children: React.ReactNode; value: number }> = (props) => (
  <p className={`height-${props.value}`}>{props.children}</p>
);





function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Intial/>
        <BarcodeScanner enabled={true} config={{
                    intervalBetweenKeyPress: 100,
                    scanningEndTimeout: 200,
                    debug: true,
                    ignoreOnInput: false,
                    historyLength: 0,
                }}/>
      </Provider>
    </div>
  );
}



export default App;
