import { FC, useState, useCallback, useEffect } from "react";
import { Col, Input, Row, Empty, Spin, Modal, Form, Button } from "antd";
import { useSelector } from "react-redux";
import {
  productsRequest,
  intiatedEditProduct,
  cancelSaveProduct,
  addProductToCartFromBarcode,
} from "../../state/products/actions";
import { useDispatch } from "react-redux";
import {
  productsSearchSelector,
  productsInprogressSelector,
  showEditCreateModalSelector,
  currentEditProductSelector,
  productSubmitLoading,
} from "../../state/products/selector";
import ProductItem from "../../components/productItem";
import _debounce from "lodash/debounce";
import { IProductItem } from "../../state/products/initialState";
import EditCreateProduct from "../../components/editCreateProduct";
import { isAuthSelector } from "../../state/app/selectors";
import Loader from "../../components/loader";
import { BarcodeReader } from "../../components/BarcodeReader";
import { BarcodeOutlined } from "@ant-design/icons";

export const Products: FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const dispatch = useDispatch();
  const inProgress = useSelector(productsInprogressSelector);
  const showEditCreateModal: boolean = useSelector(showEditCreateModalSelector);
  const currentProduct: IProductItem | null = useSelector(
    currentEditProductSelector
  );
  const submitLoading: boolean = useSelector(productSubmitLoading);
  const isAuth: boolean = useSelector(isAuthSelector);
  const [showBarCodeModal, setShowBarCodeModal] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState(false);


  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(productsRequest());
  }, []);

  const handler = useCallback(
    _debounce((val) => setSearchValue(val), 600),
    []
  );

  // Placeholder search for now, later will be handled via API .
  const products = useSelector(productsSearchSelector(searchValue));

  const handleModelCancel = () => {
    if (submitLoading) return;
    form.resetFields();
    dispatch(cancelSaveProduct());
  };

  const handleBarcodeDecoded = (barcode: string) => {
    if (!isWaiting) {
      setIsWaiting(true)
      dispatch(addProductToCartFromBarcode({ barcode }));
      setTimeout(()=> setIsWaiting(false), 3000)
    }
  };

  const createNewProduct = () => {
    dispatch(
      intiatedEditProduct({
        product: {
          id: "__NEW__",
          description: "",
          title: "",
          unitLabel: "KG",
          unitPrice: 0,
        },
      })
    );
  };

  return (
    <>
      {inProgress && <Loader />}
      {currentProduct && (
        <Modal
          open={showEditCreateModal}
          title={"Edit Product"}
          okText={"Save"}
          onCancel={handleModelCancel}
          onOk={form.submit}
          okButtonProps={{ disabled: submitLoading, loading: submitLoading }}
          cancelButtonProps={{
            disabled: submitLoading,
            loading: submitLoading,
          }}
        >
          <EditCreateProduct formRef={form} product={currentProduct} />
        </Modal>
      )}
      {showBarCodeModal && (
        <Modal
          open={showBarCodeModal}
          title="Scan Bar Code"
          // visible={showBarCodeModal}
          onCancel={() => setShowBarCodeModal(false)}
          footer={null}
          width={"50%"}
        >
          <BarcodeReader onBarcodeDecoded={handleBarcodeDecoded} />
        </Modal>
      )}

      <div style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Row
          justify={"space-between"}
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <Col xl={2} xs={24}>
            {isAuth && <Button onClick={createNewProduct}>Add New</Button>}
          </Col>

          <Col xl={8} xs={18}>
            <Row>
              <Col xl={22} xs={20}>
                <Input.Search
                  size="large"
                  width={50}
                  placeholder="Search here"
                  onSearch={handler}
                  onChange={(e) => handler(e.target.value || "")}
                  allowClear
                />
              </Col>
              <Col xl={2} xs={4}>
                <Button
                  style={{ height: "100%" }}
                  icon={<BarcodeOutlined />}
                  onClick={() => setShowBarCodeModal(true)}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-around" gutter={[16, 24]} align="middle">
          {products.map((product, index) => {
            return (
              <Col
                xs={24}
                xl={8}
                md={7}
                key={`Products--${index}-${product.id}`}
              >
                <ProductItem
                  description={product.description}
                  title={product.title}
                  image={product.image}
                  key={`Products-${index}`}
                  id={product.id}
                  unitLabel={product.unitLabel}
                  unitPrice={product.unitPrice}
                  active={product.active}
                  productCode={product.productCode}
                />
              </Col>
            );
          })}
          {products.length <= 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>No Product found</span>}
            />
          )}
        </Row>
      </div>
    </>
  );
};
