export  const routes = {
    HOME: '/',
    PRODUCTS: '/products',
    CART: '/cart',
    INVOICES: '/invoices',
    BILL: '/invoice/:id',
    PRE_ORDERS: '/pre_orders',
    NEW_PRE_ORDER: '/pre_orders/new',

    LOGIN: '/login'
}