import { takeEvery, all, put, call } from "redux-saga/effects";
import {PRE_ORDER_PRODUCT_LIST_REQ, preOrderProductListReqComplete} from './actions'
import { getFirestore, collection, getDocs, doc, updateDoc, setDoc, getDoc, query, where, orderBy } from 'firebase/firestore';
import { selectorType } from "./initialState";


const getProducrsList = async () => {
    try {
        const db = getFirestore();

        const productsRef = collection(db, "products")

        const q = query(productsRef, orderBy("title", 'asc'), where("active", "==", true))
        const docsSnapShot = await getDocs(q);

        const productsList: selectorType[] = [];

        docsSnapShot.forEach((document: any) => {
            const {title, id} = document.data();
            productsList.push({label: title, value: id});
        })

        return productsList;

    } catch (error) {
        console.error(error);
        return []
    }
}

function* getProductsListForPreOrders() {
    try {
        
        const products:selectorType[] = yield call(getProducrsList)
        console.log("producrs ---> ", products);
        yield put(preOrderProductListReqComplete({products}));

    } catch (error) {
        console.error(error);
        yield put(preOrderProductListReqComplete({products: []}));
    }
}

export default function* () {
    yield all([
        takeEvery(PRE_ORDER_PRODUCT_LIST_REQ, getProductsListForPreOrders)
    ])
}