import { FC, useEffect } from 'react';
import { Table, Col, DatePicker, Row } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { invoiceInProgress, invoicesSelector, invoicesCountSelector, invoicesPerPageSelector, invoicesRangeSelector } from '../../state/invoice/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getInvoicesRequest, dateRangeChange } from '../../state/invoice/actions';
import { FilePdfOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const { RangePicker } = DatePicker;


const Invoices: FC = () => {

    const naviagte = useNavigate();
    const dispatch = useDispatch();


    const columns: ColumnsType<any> = [
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'id',
    
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, {createdAt}) => {
                return <>{moment(createdAt).format('MMM-DD-YY hh:mm:ss')}</>
            }
        },
        {
            title: 'Subtotal',
            dataIndex: 'subtotal',
            key: 'subtotal',
    
        },
        {
            title: 'Discount Amount',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
    
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
    
        },
        {
            title: 'Payment Mode',
            dataIndex: 'modeOfPayment',
            key: 'modeOfPayment',
        },
        {
            title: '',
            dataIndex: 'details',
            key: 'details',
            render: (_, {id}) => <FilePdfOutlined onClick={() => naviagte(`/invoice/${id}`) } />
        },
    ]

    useEffect(() => {
        dispatch(getInvoicesRequest());
    }, [])

    const handlePagination = (pagination: TablePaginationConfig) => {
        console.log("-----> ")
        console.log(pagination.current)
        dispatch(getInvoicesRequest());
    }

    const inProgress = useSelector(invoiceInProgress);
    const invoices = useSelector(invoicesSelector)
    const count = useSelector(invoicesCountSelector)
    const perPageCount = useSelector(invoicesPerPageSelector)
    const selectedRange = useSelector(invoicesRangeSelector)

    const onPickerComplete = (values: any, formatString: [string, string])  => {
        
        dispatch(dateRangeChange(values));
        dispatch(getInvoicesRequest());        
    }

    return <>
        <Row style={{ marginTop: 10, marginBottom: 10 }} justify='center'>
            <Col>
                <RangePicker value={selectedRange}
                // allowClear={false}
                 onChange={onPickerComplete}
                  />        
            </Col>
        </Row>

        <Col >
            <Table columns={columns} dataSource={invoices} 
                loading={inProgress}  rowKey={"id"}/>
        </Col>
    </>
}

export default Invoices;