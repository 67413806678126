import { all, call, takeEvery, put, take, fork, select } from 'redux-saga/effects';
import {LOGIN, loginSuccess, loginFailure, LOG_OUT, logOutSucess} from './actions';
import {signInWithEmailAndPassword, getAuth, setPersistence, browserSessionPersistence, signOut} from 'firebase/auth'
import {initApp} from '../app/actions';
function* loginUser(action: any) {
    try {
        const {email, password} = action.payload;
        const auth = getAuth();
        yield call(setPersistence, auth, browserSessionPersistence);
        yield call(signInWithEmailAndPassword, auth, email, password)

        yield put(loginSuccess())
        yield put(initApp());
        

    } catch (error) {
        console.error(error);
        yield put(loginFailure())
        // toast.error("Login Failed, please try again.")
    }
}

function* logoutUser() {
    try {
        const auth = getAuth();
        yield call(signOut, auth);
        yield put(logOutSucess())
        // toast.success("Logout success");
    } catch (error) {
        console.error(error);
        yield put(logOutSucess());
        // toast.error("Logout Failed, please try again.")
    }
}

export default function* () {
    yield all([
        takeEvery(LOGIN, loginUser),
        takeEvery(LOG_OUT, logoutUser),
    ])
}