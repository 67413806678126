import dayJS from 'dayjs';
export interface IinvoiceState {
    inProgress: boolean;
    createInProgress: boolean;
    invoices: IinvoiceItem[];
    invoiceDetails?: InvoiceDetails;
    invoicesCount: number;
    invoiceLastItem?: any;
    invoicesPerPage: number;
    dateRange: [dayJS.Dayjs, dayJS.Dayjs];
}


export interface IinvoiceItem {
    contact: string;
    customerName: string;
    discountAmount: number;
    id: string;
    modeOfPayment: string;
    remarks: string;
    subtotal: number;
    totalAmount: number;
    createdAt: number;
}

export interface IInvoiceProductItem {
    [x: string]: any;
    discountAmount: number;
    id: string;
    productId: string;
    quantity: number;
    unitLabel: string;
    unitPrice: number;
    title: string;
    
}


export interface InvoiceDetails {
    invoiceItems: IInvoiceProductItem[];
    invoiceDetail: IinvoiceItem 
}

export const initialState: IinvoiceState = {
    inProgress: false,
    createInProgress: false,
    invoices: [],
    invoiceDetails: undefined,
    invoicesCount: 0,
    invoiceLastItem: undefined,
    invoicesPerPage: 30,
    dateRange: [dayJS().startOf('day'), dayJS().endOf('day')]
}