import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';
import { Store } from '../rootReducer'
import { initialState } from './initialState';

const appStateSelector = (state: Store) => state.app || initialState;


export const currentUserSelector = createSelector(
    appStateSelector,
    appState => appState.currentUser
);

export const isAuthSelector = createSelector(
    appStateSelector,
    appState => appState.currentUser && appState.currentUser.email ? true : false
);

export const appInProgressSelector = createSelector(
    appStateSelector,
    appState => appState.inProgress
);

export const navigationSelector = createSelector(
    appStateSelector,
    appState => appState.navigate
);