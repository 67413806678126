import { createAction } from '@reduxjs/toolkit'
import {ICartItems, IProductItem, IProductItemEdit} from './initialState';
import {BARCODE_SCANNED} from 'react-usb-barcode-scanner';

interface IAddProduct{
    productId: string;
    quantity: number;
    price: number;
    unit: string;

}

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const productsRequest = createAction(PRODUCTS_REQUEST);

export const PRODUCTS_REQUEST_COMPLETE = "PRODUCTS_REQUEST_COMPLETE";
export const productsRequestComplete = createAction<Array<IProductItem>>(PRODUCTS_REQUEST_COMPLETE);

export const ADD_PRODUCT_CART = 'ADD_PRODUCT_CART';
export const addProductCart = createAction<IAddProduct>(ADD_PRODUCT_CART);

export const USB_BARCODE_SCANNED = BARCODE_SCANNED;
export const usbBarCodeScanned = createAction(USB_BARCODE_SCANNED);

export const ADD_PRODUCT_TO_CART_FROM_BARCODE = 'ADD_PRODUCT_CART_FROM_BARCODE';
export const addProductToCartFromBarcode = createAction<{barcode: string}>(ADD_PRODUCT_TO_CART_FROM_BARCODE)

export const ADD_PRODUCT_CART_SUCCESS = 'ADD_PRODUCT_CART_SUCCESS';
export const addProductCartSuccess = createAction<{currentItems: Array<ICartItems>}>(ADD_PRODUCT_CART_SUCCESS);

export const REMOVE_PRODUCT_CART = 'REMOVE_PRODUCT_CART';
export const removeProductCart = createAction<{productId: string, cartId: string}>(REMOVE_PRODUCT_CART);

export const INTIATED_EDIT_PRODUCT = 'INTIATED_EDIT_PRODUCT';
export const intiatedEditProduct = createAction<{product: IProductItem}>(INTIATED_EDIT_PRODUCT);

export const SAVE_PRODUCT = 'SAVE_PRODUCT';
export const saveProduct = createAction<{product: IProductItemEdit}>(SAVE_PRODUCT);

export const SAVE_PRODUCT_COMPLETE = 'SAVE_PRODUCT_COMPLETE';
export const saveProductComplete = createAction<{product: IProductItem}>(SAVE_PRODUCT_COMPLETE);

export const CANCEL_SAVE_PRODUCT = 'CANCEL_SAVE_PRODUCT';
export const cancelSaveProduct = createAction(CANCEL_SAVE_PRODUCT);

export const EDIT_DISCOUNT_AMOUNT = 'EDIT_DISCOUNT_AMOUNT';
export const editDiscountAmount = createAction<{productId: string, discountAmount: number}>(EDIT_DISCOUNT_AMOUNT)

export const RESET_CART = 'RESET_CART';
export const resetCart = createAction(RESET_CART);