import { FormInstance, Form, Input, Select, InputNumber, Upload, Button, Radio} from 'antd';
import { saveProduct } from '../../state/products/actions';
import { useDispatch } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import { IProductItem } from '../../state/products/initialState';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';


interface IProps {
    formRef: FormInstance<any>
    product: IProductItem
}

export const EditCreateProduct: FC<IProps> = ({ formRef, product }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            formRef.resetFields();
        }
    }, [])

    const [labelValue, setLabelValue] = useState<string>('');
    

    const lableOptions = [
        {label: 'KG', value: 'KG'},
        {label: '1/2 KG', value: '1/2 KG'},
        {label: '1 pc', value: '1 pc'},
        {label: 'Other', value: 'Other'},
        
    ]

    const { title, description, id, unitLabel, unitPrice, image, active, productCode } = product;

    const onSubmit = (values: any) => {
        const {title, description, unitLabel, unitPrice, image, active, unitLabelInput, productCode} = values;

        let unitLabelValue = labelValue === 'Other' ? unitLabelInput : unitLabel;

        dispatch(saveProduct({product: {title, description, id, unitLabel: unitLabelValue, unitPrice, image, active: active === 'active' ? true : false, productCode}}))
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const initialFormData = { title, description, unitLabel, unitPrice, image: image&&[{uid: title, name: title, status: 'done', url: image}], id, productCode }
    return <>
        <Form form={formRef}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={initialFormData}
            onFinish={onSubmit}
            autoComplete="off"
        >

            <Form.Item label="Title" name="title"
                rules={[{ required: true, message: 'Please input title!' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Description" name="description">
                <Input />
            </Form.Item>

        <Form.Item label="status" name="active" initialValue={active === false? 'in-active' : 'active'}>
          <Radio.Group>
            <Radio value="active"> Active </Radio>
            <Radio value="in-active"> In Active </Radio>
          </Radio.Group>
        </Form.Item>

            <Form.Item label="Unit Label" name="unitLabel">
                <Select options={lableOptions} defaultActiveFirstOption 
                    onChange={(value, _) => setLabelValue(value)}>
                </Select>
            </Form.Item>

            {
                labelValue === 'Other' && <Form.Item label="Specify Unit Label" name="unitLabelInput"
                rules={[{ required: true, message: 'Please input unit or select'}, 
                        {max: 5, min: 1, message: 'Input label between 1 and 5 characters'}]}>
                    <Input />
                </Form.Item>
            }

            <Form.Item label="Unit Price" name="unitPrice"
                rules={[{ required: true, message: 'Please input price!' }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item label="Product Code" name="productCode"
                rules={[{ required: true, message: 'Please product code!' }]}>
                <InputNumber />
            </Form.Item>

            {
                
                    <Form.Item
                        name="image"
                        label="Image"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}

                    >
                        <Upload beforeUpload={() => false} maxCount={1} name="image" listType="picture" 
                            multiple={false} accept='.png, .jpeg, .jpg'>
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
            }
        </Form>
    </>
}