import { createAction } from '@reduxjs/toolkit'


export const LOGIN = "LOGIN";
export const login = createAction<any>(LOGIN);

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const loginSuccess = createAction(LOGIN_SUCCESS);

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const loginFailure = createAction(LOGIN_FAILURE);


export const LOG_OUT = "LOG_OUT";
export const logOut = createAction(LOG_OUT);

export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const logOutSucess =  createAction(LOG_OUT_SUCCESS);