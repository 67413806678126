import colors from "./colors"
import { CSSProperties } from "react";

interface IStyles {
    [x:string]: CSSProperties
}
const styles: IStyles = {
    iconPrimary: {
        color: colors.primary,
    },
    homePageContainer: {
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10
    }
}

export default styles;