import { createAction } from '@reduxjs/toolkit'
import { INavigate } from './initialState';

export const INIT_APP = "INIT_APP";
export const initApp = createAction(INIT_APP);

export const INIT_SUCCESS = "INIT_SUCCESS";
export const initSuccess = createAction<any>(INIT_SUCCESS);

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const getUserDetails = createAction<any>(GET_USER_DETAILS);


export const RESET_NAVIGATE = 'RESET_NAVIGATE';
export const resetNavigate = createAction(RESET_NAVIGATE);

export const NAVIGATE = 'NAVIGATE'
export const navigate = createAction<{data?: INavigate}>(NAVIGATE);