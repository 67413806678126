import {  createReducer } from '@reduxjs/toolkit';
import {initialState, IAuth} from './initialState';
import {login, loginSuccess, loginFailure} from './actions';

const appReducer = createReducer(initialState, (builder) => {
    builder.addCase(login, (state, action) => {
        state.inProgress = true;
    })
    .addCase(loginSuccess, (state, action) => {
        state.inProgress = false;
    })
    .addCase(loginFailure, (state, action) => {
        state.inProgress = false;
    })
})
export default appReducer;