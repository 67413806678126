import { v4 as uuidv4 } from 'uuid';
import {  createReducer } from '@reduxjs/toolkit';
import {initialState, ICartItems} from './initialState';
import {ADD_PRODUCT_CART_SUCCESS, ADD_PRODUCT_CART, REMOVE_PRODUCT_CART, PRODUCTS_REQUEST, PRODUCTS_REQUEST_COMPLETE, 
    INTIATED_EDIT_PRODUCT, SAVE_PRODUCT, SAVE_PRODUCT_COMPLETE, CANCEL_SAVE_PRODUCT, EDIT_DISCOUNT_AMOUNT, RESET_CART} from './actions'



const productsReducer = createReducer(initialState, (builder) => {
    builder.addCase(ADD_PRODUCT_CART_SUCCESS, (state, action: any) => {
        const {currentItems}: {currentItems: Array<ICartItems>} = action?.payload;
        
        return {...state, cartItems: [...currentItems]}
    })
    .addCase(ADD_PRODUCT_CART, (state, action: any) => {
        const {productId, quantity, price, unit}: {productId: string, quantity: number, price: number, unit: string } = action?.payload;
        const index = state.cartItems.findIndex((cartItem) => cartItem.productId === productId);
        const discountAmount = 0;
        const cartId: string = uuidv4()
        state.cartItems.push({cartId: cartId,productId, quantity, unitLabel: unit, unitPrice: price, discountAmount})
    })
    .addCase(REMOVE_PRODUCT_CART, (state, action: any) => {
        const {productId, cartId} = action?.payload;
        const index = state.cartItems.findIndex((cartItem) => cartItem.cartId === cartId);
        if (index >= 0) {
            state.cartItems.splice(index, 1);
        }
    })
    .addCase(PRODUCTS_REQUEST_COMPLETE, (state, action: any) => {
        state.products = action?.payload;
        state.loading = false;
    })
    .addCase(PRODUCTS_REQUEST, (state, action: any) => {
        state.loading = true;
    })
    .addCase(INTIATED_EDIT_PRODUCT, (state, action: any) => {
        const {product} = action?.payload;
        state.showEditCreateModal = true;
        state.currentProduct = product;

    })
    .addCase(SAVE_PRODUCT,  (state, action: any) => {
        state.submitLoading = true;
    })
    .addCase(SAVE_PRODUCT_COMPLETE, (state, action: any) => {
        const {product} = action.payload;
        const products = state.products;

        const index = products.findIndex(p => p.id === product.id);
        if (index >= 0) {
            products.splice(index, 1, product)
        } else {
            products.push(product)
        }

        state.products = products;
        state.submitLoading = false;
        state.showEditCreateModal = false;
        state.currentProduct = null;
    })
    .addCase(CANCEL_SAVE_PRODUCT, (state, action: any) => {
        state.submitLoading = false;
        state.showEditCreateModal = false;
        state.currentProduct = null;
    })
    .addCase(EDIT_DISCOUNT_AMOUNT, (state, action: any) => {
        const {productId, discountAmount} = action.payload;
        const cartItems = state.cartItems;
        const index = cartItems.findIndex(p => p.productId === productId);
        cartItems[index].discountAmount = discountAmount;
        state.cartItems = cartItems;
    })
    .addCase(RESET_CART, (state, action) => {
        state.cartItems = [];
    })
})

export default productsReducer;