import { FC, useState } from 'react';
import { Table, Row, Typography, Modal, Col, Button, Form, Radio, Input, Select, InputNumber, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { allProductsSelector, allProductsInCartSelector, paymentDetailsSelector } from '../../state/products/selector';
import { ICartItems } from '../../state/products/initialState';
import { useSelector, useDispatch } from 'react-redux';
import { FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';
import { editDiscountAmount, removeProductCart } from '../../state/products/actions';
import { round } from 'lodash';
import './cart.css'
import { createInvoice } from '../../state/invoice/actions';
import { invoiceCreateInProgressSelector } from '../../state/invoice/selectors';
import PhoneNumberPrefix from '../../components/phoneNumberPrefix';

const { Title} = Typography;


export const Cart: FC = () => {
    const dispatch = useDispatch();
    const allProducts = useSelector(allProductsSelector)
    const allItemsIncart = useSelector(allProductsInCartSelector)
    const amountBreakDown = useSelector(paymentDetailsSelector);

    const invoiceInProgress = useSelector(invoiceCreateInProgressSelector);

    
    const [modal, setModal] = useState<boolean>(false);


    const renderItemDescription = (productId: string) => {

        const product = allProducts.find(p => p.id === productId);

        if (!product) {
            return '-'
        }

        return <>
            <Row>
                <Title level={5}>
                    {product.title}
                </Title>
            </Row>
            <Row>{product.description}</Row>
        </>
    }


    const onSubmit = (values: any) => {
        dispatch(createInvoice({...values, ...amountBreakDown}))
    }

    const [form] = Form.useForm();

    const handleDiscountChange = (value: number | null, productId: string) => {
        dispatch(editDiscountAmount({productId, discountAmount: value||0}));
    }


    const removeItem = (productId: string, cartId: string) => {
        dispatch(removeProductCart({productId, cartId}))
    }

    const columns: ColumnsType<ICartItems> = [
        {
            title: 'Item Description',
            dataIndex: 'item_description',
            key: 'productId',
            render: (_, { productId }) => {
                return renderItemDescription(productId);
            }
        },
        {
            title: 'Price (₹)',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (_, {unitPrice, discountAmount}) => {
                return <>{discountAmount > 0 ? <>
                    <s>{unitPrice}</s>
                    {' ' + round(unitPrice-discountAmount)}
                </> : unitPrice}</>
            }
        },
        {
            title: 'Discount Amount (₹)',
            dataIndex: 'discount',
            key: 'productId-discount',
            render: (_, { discountAmount, productId }) => {
                return <><InputNumber defaultValue={0} step={1} min={0} 
                    value={discountAmount} onChange={(val) => handleDiscountChange(val, productId)}/></>
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (_, { quantity, unitLabel }) => {
                return <>{quantity} ({unitLabel})</>
            }
        },
        {
            title: 'Total (₹)',
            dataIndex: 'total',
            key: 'productId-unitprice',
            render: (_, { quantity, unitPrice, discountAmount }) => {
                return <>{(quantity * (unitPrice-discountAmount))}</>
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, { productId, cartId }) => {
                return <>
                <Tooltip title="Remove item">
                    <DeleteOutlined onClick={() => removeItem(productId, cartId)} style={{color: 'red', fontSize: 20}}/>
                </Tooltip></>
            }
        }
    ];

    return <>

        <Modal open={modal} title={'Place Checkout'} okText={'Place and Print'}  okButtonProps={{ icon: <FilePdfOutlined />, htmlType: 'submit', loading: invoiceInProgress }} onCancel={() => setModal(!modal)}
            onOk={form.submit}>
            <Form form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                autoComplete="off"
            >
                <Form.Item label="Payment Mode" name={"modeOfPayment"} initialValue="cash">
                    <Radio.Group >
                        <Radio value="cash"> Cash </Radio>
                        <Radio value="online"> Online </Radio>
                        <Radio value="others"> Others </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Remarks" name={'remarks'}>
                    <Input />
                </Form.Item>
                <Form.Item label="Customer Name" name="customerName">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Phone Number" >
                    <Input addonBefore={<PhoneNumberPrefix />} style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>
        <Row style={{ marginTop: 10, marginBottom: 10 }} justify='center'>
            <Col>
                {allItemsIncart.length >  0 && <Button size='large' onClick={() => setModal(!modal)}>
                    Checkout
                </Button>}
            </Col>
        </Row>
        <Row>
            <Col xs={24} xl={19}>
                <Table columns={columns} dataSource={allItemsIncart} pagination={false} rowKey={"cartId"} />
            </Col>
            <Col xs={24} xl={5}>
                <div style={{ border: '1px solid grey', borderRadius: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5 }}>
                    <Row>
                        <Col span={18}><Title level={3}>Payment Details:</Title></Col>
                    </Row>
                    <Row>
                        <Col span={18}><Title level={5}>Subtotal:</Title></Col>
                        <Col span={6}>
                            <Title level={5}>{amountBreakDown.subtotal}</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}><Title level={5}>Discount Amount:</Title></Col>
                        <Col span={6}>
                            <Title level={5}>{amountBreakDown.discountAmount}</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}><Title level={5}>Total:</Title></Col>
                        <Col span={6}>
                            <Title level={5}>{amountBreakDown.totalAmount}</Title>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

    </>
}