
import  { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigationSelector } from '../../state/app/selectors';
import { resetNavigate } from '../../state/app/actions';

// this component is only used to listen for changes in app.navigate state and navigate to that route

const NavigationHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationData = useSelector(navigationSelector);

  useEffect(() => {
    if (navigationData && navigationData?.route) {
        navigate(navigationData?.route, {state: navigationData.state})
        dispatch(resetNavigate());
    }

  }, [navigationData, navigationData?.route])

  return <></>

};

export default NavigationHandler;
