
export interface INavigate {
    route: string;
    state: {
        [x: string]: any
    }
}

export interface IApp {
    currentUser: any
    inProgress: boolean
    navigate?: INavigate

}

export const initialState: IApp = {
    currentUser: null,
    inProgress: true,
    navigate: undefined
};