import { all, call, takeEvery, put, take, fork, select } from 'redux-saga/effects';
import { CREATE_INVOICE, createInvoiceComplete, GET_INVOICES_REQUEST, getInvoicesComplete, GET_INVOICE, getInvoiceComplete } from './actions';
import { getFirestore, collection, getDocs, doc, writeBatch, getDoc, query, where, serverTimestamp, QuerySnapshot, orderBy, limit, startAt, startAfter, getCountFromServer, DocumentData } from 'firebase/firestore';
import { allProductsInCartSelector } from '../products/selector';
import { ICartItems } from '../products/initialState';
import { IinvoiceItem, IInvoiceProductItem } from './initialState';
import { navigate } from '../app/actions';
import { resetCart } from '../products/actions';
import {invoicesPerPageSelector, invoicesRangeSelector} from './selectors'
import dayJS from 'dayjs';

function* recordInvoice(action: any) {
    try {
        const { payload } = action;

        const { customerName = '', discountAmount = 0, modeOfPayment = 'cash', phone = '', prefix = '', remarks = '', subtotal = 0, totalAmount = 0 } = payload
        const db = getFirestore();

        const batch = writeBatch(db);

        const itemRef = doc(collection(db, "invoice"));

        batch.set(itemRef, { id: itemRef.id, customerName, discountAmount, modeOfPayment, contact: prefix + phone, remarks, subtotal, totalAmount, createdAt: serverTimestamp() })

        const cartItems: ICartItems[] = yield select(allProductsInCartSelector)

        cartItems.forEach((cartItem) => {
            const cartItemRef = doc(collection(db, "invoice_item"))
            batch.set(cartItemRef, { id: cartItemRef.id, invoiceId: itemRef, ...cartItem })
        })


        yield call(() => batch.commit());

        yield put(navigate({data: {route: `invoice/${itemRef.id}`, state: {autoPrint: true}}}))
        yield put(resetCart());

    } catch (error) {
        console.error("error --> ", error)
    }

    yield put(createInvoiceComplete())

}


const getInvoices = async (selectedDateRange: any, perPage: number = 30, lastVisible?: any) => {
    // TODO: add pagination ... 
    // NOTE: pagination not completed yet
    const db = getFirestore();

    

    const invoicesRef = collection(db, "invoice")
    let q = query(invoicesRef, orderBy('createdAt', 'desc'), limit(perPage))

    if (lastVisible) {
        q = query(q, startAfter(lastVisible))
    }

    if (selectedDateRange) {
        q = query(q, where('createdAt', '>=', selectedDateRange[0]), where('createdAt', '<=', selectedDateRange[1]))
    }

    const snapshot = await getCountFromServer(query(invoicesRef));
    const count = snapshot.data().count;

    const docsSnapShot = await getDocs(q);
    return {docs: docsSnapShot, count}
}

const formatDates = (selectedDateRange: [dayJS.Dayjs, dayJS.Dayjs]) => {
    try {
        return [selectedDateRange[0].startOf('day').toDate(), selectedDateRange[1].endOf('day').toDate()]
    } catch (error) {
        const today = dayJS()
        return [today.startOf('day').toDate(), today.endOf('day').toDate()];
    }
}

function* invoicesRequest() {
    try {

        // const pageCount: number = yield select(invoicesPerPageSelector);
        // const lastVisibleItem: object = yield select(invoicesLastVisibleSelector);
        const selectedDateRange: [dayJS.Dayjs, dayJS.Dayjs]  = yield select(invoicesRangeSelector)
        const formattedDates = formatDates(selectedDateRange)
        const {docs: docsSnapShot, count}: {docs: QuerySnapshot<DocumentData>, count: number} = yield call(getInvoices, formattedDates);
        const invoices: IinvoiceItem[] = []
        const lastVisible = docsSnapShot.docs[docsSnapShot.docs.length - 1]


        docsSnapShot.forEach((document: any) => {
            
            // created has {seconds: 00, nanoseconds: 00} conver seconds into milli seconds 
            const createdAt = document.data().createdAt.seconds*1000;
            invoices.push({...document.data() as IinvoiceItem, createdAt});
        })

        yield put(getInvoicesComplete({invoices, count, lastVisible}))
        
    } catch (error) {
        console.error(error)
        yield put(getInvoicesComplete({invoices: [], count: 0}))
    }
}



const getData = async (invoiceId: string) => {
    try {
        const db = getFirestore();
        const itemRef = doc(db, "invoice", invoiceId);

        const invoiceDoc = await getDoc(itemRef);
        const createdAt = invoiceDoc.data()?.createdAt.seconds*1000;
        const invoiceData = {...invoiceDoc.data(), createdAt};

        const invoiceItemsRef = collection(db, "invoice_item")

        const q = query(invoiceItemsRef, where("invoiceId", "==", itemRef))

        const invoiceItems: QuerySnapshot = await getDocs(q);
        
        const dataPromises: Promise<any>[] = []

        invoiceItems.forEach(async invoice =>  {
            const invoiceItemData = invoice.data();
            const productRef =  doc(db, "products", invoiceItemData['productId']);
            
            const rd = new Promise(async (resolve, reject) => {
                const product = await getDoc(productRef);
                return resolve({...invoiceItemData, ...product.data()} as IInvoiceProductItem)
            })
            dataPromises.push(rd);
        });
        const invoiceItemsData = await Promise.all(dataPromises);
        return {invoiceItems: invoiceItemsData, invoiceData};

    } catch (error) {
        console.error(error);
    }
    return {invoiceData: undefined, invoiceItems: []};
}


function* getInvoiceData(action: any){
    const {invoiceId} = action.payload;

    try {
        const {invoiceItems, invoiceData}: {invoiceItems: IInvoiceProductItem[], invoiceData: IinvoiceItem} = yield call(getData, invoiceId)
        yield put(getInvoiceComplete({invoiceDetail: invoiceData, invoiceItems}));
    } catch (error) {
        console.error(error);
        yield put(getInvoiceComplete({invoiceDetail: undefined, invoiceItems: []}));
    }
    

}

export default function* () {
    yield all([
        takeEvery(CREATE_INVOICE, recordInvoice),
        takeEvery(GET_INVOICES_REQUEST, invoicesRequest),
        takeEvery(GET_INVOICE, getInvoiceData),
    ])
}