import { configureStore,  } from '@reduxjs/toolkit'
import rootReducers from '../state/rootReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../state/rootSaga';


const sagaMiddleware = createSagaMiddleware()
const store = configureStore({reducer: rootReducers, middleware: [sagaMiddleware], devTools: process.env.NODE_ENV !== 'production'})
sagaMiddleware.run(rootSaga);

export default store;