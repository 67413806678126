import {  createReducer } from '@reduxjs/toolkit';
import {initialState, IApp} from './initialState';
import {initSuccess, initApp, navigate, resetNavigate} from './actions';

const appReducer = createReducer(initialState, (builder) => {
    builder.addCase(initSuccess, (state, action: any) => {
        const {currentUser}: {currentUser: any} = action?.payload;
        state.currentUser = currentUser;
        state.inProgress = false;
    }).addCase(initApp, (state, action) => {
        state.inProgress = true;
    })
    .addCase(resetNavigate, (state, action) => {
        state.navigate = undefined;
    })
    .addCase(navigate, (state, action) => {
        state.navigate = action.payload.data;
    })
})
export default appReducer;