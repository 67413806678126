import { FC } from "react";
import { FloatButton, Layout, Row, Col } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import colors from "../../consts/colors";
import styles from "../../consts/styles";

const { Footer } = Layout;


const HomeScreen: FC = () => {
    return <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <div style={{ ...styles.homePageContainer }}>
            <h1 style={{ color: colors.primary, fontWeight: 'bolder' }}>Hello, Welcome to Mana Inti Ruchulu</h1>
            <p style={{ fontWeight: 'bolder' }}>We are thrilled to invite you to a culinary adventure that captures the essence of our region's delectable flour snacks, sweets, and pastries.</p>
        </div>

        <div style={{ ...styles.homePageContainer, backgroundColor: '#FBBF77' }}>
            <h2>First Time Here?</h2>
            <p>If you're new to Mana Inti Ruchulu (మన ఇంటి రుచులు), we invite you to experience the delight of our homemade snacks and sweets.</p>
            <p>Join our community of satisfied customers, and let us be a part of your culinary journey.</p>
            <p>Contact Us right away with below mentioned details or via Whatsapp.</p>

        </div>

        <div style={{ ...styles.homePageContainer, backgroundColor: '#e3c797' }}>
            <h2>Embracing Our Community</h2>
            <p>To all our previous customers, we express our deepest gratitude for your trust and loyalty.</p>
            <p>Your decision to revisit us warms our hearts, and we're delighted to have been a part of your snacking and sweet experiences. </p>
            <p>Each interaction has been a milestone in our commitment to delivering the best.</p>

        </div>
        <div style={{ ...styles.homePageContainer, backgroundColor: '#DAF7A6' }}>
            <h2>Honoring Our Big Customer's</h2>
            <p>In particular, we wish to honor the exceptional support of our esteemed big customers.</p>
            <p style={{ fontWeight: 'bold' }}>Alphores Educational Institutions, Chaitanya Group of Institutions, Prathibha High School.</p>
            <p>Your support has played an instrumental role in shaping our journey, and we're humbled by the trust you've placed in us.</p>
        </div>

        <FloatButton
            shape="circle"
            href="https://api.whatsapp.com/message/RHXMIVQ6IA26C1"
            style={{ right: 94 }}
            className="custom-float-button"
            icon={<WhatsAppOutlined />}
        />
    </div>
};

export default HomeScreen;
