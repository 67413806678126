export type selectorType = {
    label: string,
    value: string
}

export type PreOrderItem = {
    productId: string;
    productName: string;
    unitLabel: string;
    quantity: number;
    notes?: string;
}

export interface IPreOrdersState {
    productsList: selectorType[];
    inProgress: boolean;
    orderItems: PreOrderItem[];
}


export const initialState: IPreOrdersState = {
    productsList: [],
    inProgress: false,
    orderItems: [],
}