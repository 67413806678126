import {DATA} from './staticData'




export interface ISubCategories {
    value: string;
    label: string;
    price: number;
    unit: 'grams' | 'kilograms'
}

export interface IProductItem {
    title: string;
    description: string;
    image?: string;
    unitPrice: number;
    unitLabel: string;
    id: string;
    active?: boolean;
    productCode?: number;
}

export interface IProductItemEdit extends Omit<IProductItem, 'image'>{
    image?: any[] | undefined;
}
export interface ICartItems {
    cartId: string
    productId: string;
    quantity: number;
    unitLabel: string;
    unitPrice: number;
    discountAmount: number;
}

export interface IProducts {
    loading: boolean;
    products: Array<IProductItem>
    cartItems: Array<ICartItems>,
    showEditCreateModal: boolean,
    currentProduct: IProductItem|null;
    submitLoading: boolean;
}

export const initialState: IProducts = {
    loading: false,
    products: [],
    cartItems: [],
    showEditCreateModal: false,
    currentProduct: null,
    submitLoading: false,
}