import { FC } from 'react';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/routes';

export const PreOrders: FC = () => {
    const navigate = useNavigate();
    return <>
        <Row style={{ marginTop: 10, marginBottom: 10 }} justify='center'>
            <Col>
                <Button onClick={() => navigate(routes.NEW_PRE_ORDER)}>
                    Create New
                </Button>
            </Col>
        </Row>
    </>
}