import {  createReducer } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {preOrderProductListReq, preOrderProductListReqComplete, preOrderAddItem} from './actions'

const preOrderReducer = createReducer(initialState, (builder) => {
    builder.addCase(preOrderProductListReq, (state, action) => {
        state.inProgress = true;
    })
    .addCase(preOrderProductListReqComplete, (state, action) => {
        const {products} = action.payload;
        state.inProgress = false;
        state.productsList = products || []
    })
    .addCase(preOrderAddItem, (state, action) => {
        const {orderItem} = action.payload;
        state.orderItems.push(orderItem);
    })
})

export default preOrderReducer;