import { all, call, takeEvery, put, take, fork, select } from 'redux-saga/effects';
import {INIT_APP, initSuccess, GET_USER_DETAILS, getUserDetails} from './actions'
import { initializeApp, FirebaseApp } from "firebase/app"
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import {getStorage} from "firebase/storage";
import {getFirestore} from 'firebase/firestore';
import {firebaseConfig} from '../../consts/firebase';
import {eventChannel} from 'redux-saga'


function* authStateChange() {
    const auth = getAuth();
    return eventChannel((emit) => {
        return onAuthStateChanged(auth, user => emit({ user }));
    })
}

function* observeAuthState() {
    try {
        const authObserver: Object = yield call(authStateChange)
        while(true) {
            const data: Object = yield take(authObserver as any);
            
            const {user}:{user: any} = data as any;
            
            if (user && user.uid) {
                const {uid, email} = user
                yield put(initSuccess({currentUser: {uid, email}}))
            } else {
                yield put(initSuccess({currentUser: null}))
            }
        }
    } catch (error) {
        console.error("Observe auth status --> ", error);
    }
}


const initialize = new Promise<FirebaseApp>((resolve, reject): FirebaseApp => initializeApp(firebaseConfig, undefined))

function* init() {
    
    try {
        const app: FirebaseApp  = yield call(() => (initializeApp(firebaseConfig)));

        // yield call(getFirestore, app);
        yield call(getAuth, app);
        
        yield call(() => (getFirestore(app)));
        yield call(getStorage, app);
        
        yield fork(observeAuthState)
    } catch (error) {
        console.error("INIT APP");
        console.error(error);
        yield put(initSuccess({currentUser: null}))
    }
}


function* getUserDetailsSaga(action: any) {
    try {
        // ---> when we store user into firestore retrive info.
        // const {uid} = action?.payload||{};
        // const db = getFirestore();
        // const userRef = doc(db, "users", uid)
        // const user: DocumentSnapshot = yield call(getDoc, userRef);

        // if (user.exists()) {
        //     const data: any = user.data();
        //     yield put(initSuccess({currentUser: {...data, uid}}))
        // }

    } catch (error) {
        console.error("get user details", error);
    }
}

export default function* () {
    yield all([
        takeEvery(INIT_APP, init),
        takeEvery(GET_USER_DETAILS, getUserDetailsSaga),
    ])
}