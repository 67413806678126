import { Routes, Route, Navigate } from 'react-router-dom';
import { routes } from './routes';



import Products from '../pages/products';
import Cart from '../pages/cart';
import Invoices from '../pages/invoices';
import Bill from '../pages/bill';
import PreOrders from '../pages/preOrders';
import NewOrder from '../pages/preOrders/newOrder';

export default (
    <Routes>
        <Route path={routes.HOME} element={<Products/>}/>
        <Route path={routes.CART} element={<Cart/>} />
        <Route path={routes.INVOICES} element={<Invoices/>} />
        <Route path={routes.BILL} element={<Bill />}/>
        <Route path={routes.PRE_ORDERS} element={<PreOrders />}/>
        <Route path={routes.NEW_PRE_ORDER} element={<NewOrder />}/>
        <Route path='/*' element={<Products/>}/>
    </Routes>
)

