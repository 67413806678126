import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../rootReducer'
import { initialState } from './initialState';


const preOrderStateSelector = (state: Store) => state.preOrder || initialState;

export const preOrderInProgressSelector = createSelector(
    preOrderStateSelector, 
    (state) => state.inProgress
);


export const preOrderProductsListSelector = createSelector(
    preOrderStateSelector, 
    (state) => state.productsList
);


