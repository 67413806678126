
import { Select, Form } from "antd";
import { FC } from "react";


export const PhoneNumberPrefix: FC = () =>
    <Form.Item name="prefix" noStyle initialValue={"91"}>
        <Select style={{ width: 70 }}>
            <Select.Option value="91">+91</Select.Option>
        </Select>
    </Form.Item>
