import { all } from "redux-saga/effects";
import productsSaga from './products/sagas';
import appSaga from './app/sagas'
import authSaga from './auth/sagas'
import invoiceSaga from './invoice/sagas';
import preOrderSaga from './preOrder/sagas';

export default function* rootSaga() {
    yield all([
        productsSaga(),
        appSaga(),
        authSaga(),
        invoiceSaga(),
        preOrderSaga(),
    ])
}