import { FC, useState, useEffect } from "react";
import { Modal, Form, Select, Row, InputNumber, Input, Col, Button } from "antd";
import OrderConfirmation from './orderConfirmation';
import { useDispatch, useSelector } from "react-redux";
import { preOrderProductListReq, preOrderAddItem } from "../../../state/preOrder/actions";
import { preOrderInProgressSelector, preOrderProductsListSelector } from "../../../state/preOrder/selectors";
import Loader from "../../../components/loader";

export const NewOrder: FC = () => {

    const dispatch = useDispatch();
    const inProgress = useSelector(preOrderInProgressSelector);
    const productsList = useSelector(preOrderProductsListSelector);
    console.log("productsList --> ", productsList)


    const [modal, setModal] = useState(false);
    const [form] = Form.useForm();
    const [addItemForm] = Form.useForm();

    const onSubmit = (values: any) => {
        console.log(values);
    }

    const onSubmitAdd = (values: any) => {
        console.log("add --> ", values)
        const {} = values;
    }

    useEffect(() => {
        dispatch(preOrderProductListReq());
    }, [])


    const unitsOptions = [
        { label: 'KG', value: 'kg' },
        { label: '1 pc', value: '1 pc' },
    ]

    return <>
        {inProgress && <Loader />}
        <Modal open={modal} title={'Create new pre-order'} okText={'Save'} okButtonProps={{ htmlType: 'submit', loading: false }} onCancel={() => setModal(!modal)}
            onOk={form.submit}>

            <OrderConfirmation onSubmit={onSubmit} formRef={form} />
        </Modal>
        <div style={{ paddingLeft: '10%', paddingRight: '10%', paddingTop: 10 }}>
            <Form form={addItemForm}
                onFinish={onSubmitAdd}
                layout="vertical"
            >
                <Row>

                    <Col xs={24} sm={24} md={12} xl={6} lg={6} style={{ paddingRight: 10 }}>
                        <Form.Item label="Product" name="product"
                            rules={[{ required: true, message: 'Select a product' }]}>
                            <Select options={productsList} loading={inProgress} showSearch optionFilterProp="label" />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={12} xl={6} lg={6} md={6} style={{ paddingRight: 10 }} >
                        <Form.Item label="Units" name="units"
                            rules={[{ required: true, message: 'Select a unit' }]}>
                            <Select options={unitsOptions} />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={12} xl={4} lg={4} md={6} style={{ paddingRight: 10 }}>
                        <Form.Item label="Quantity" name="quantity" rules={[{ required: true, message: 'Input quantity' }]}>
                            <InputNumber style={{ width: '100%' }} step={0.1} min={0} />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={12} xl={5} lg={5} md={12} style={{ paddingRight: 10 }}>
                        <Form.Item label="Notes" name="notes">
                            <Input.TextArea rows={1} />
                        </Form.Item>
                    </Col>

                    <Col sm={12} xs={12} xl={3} lg={3} md={12} style={{ alignSelf: 'center' }}>
                        {/* <Form.Item label="" name=""> */}
                        <Row justify={"space-between"}>
                            <Button type="primary" htmlType="submit">
                                Add
                            </Button>
                            <Button htmlType="reset">
                                Reset
                            </Button>
                        </Row>

                        {/* </Form.Item> */}
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}