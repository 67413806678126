import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../rootReducer'
import { initialState } from './initialState';

const invoiceStateSelector = (state: Store) => state.invoice || initialState;


export const invoiceCreateInProgressSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.createInProgress
);

export const invoiceInProgress = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.inProgress
);

export const invoicesSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.invoices
);

export const invoiceDetailsSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.invoiceDetails
);

export const invoicesCountSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.invoicesCount
);

export const invoicesLastVisibleSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.invoiceLastItem
);

export const invoicesPerPageSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.invoicesPerPage
);

export const invoicesRangeSelector = createSelector(
    invoiceStateSelector,
    invoiceState => invoiceState.dateRange
);