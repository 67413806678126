import React, { FC, useEffect, useState } from 'react';
import { Tooltip, Button, Card, Space, Typography, InputNumber, Row, Col } from 'antd';
import {  IProductItem } from '../../state/products/initialState'
import { useSelector, useDispatch } from 'react-redux';
import { productCartValueSelector } from '../../state/products/selector';
import { addProductCart, removeProductCart, intiatedEditProduct } from '../../state/products/actions'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { isAuthSelector } from '../../state/app/selectors';


const { Text } = Typography;

export const ProductItem: FC<IProductItem> = ({ title, description, image, id, unitPrice, unitLabel, active, productCode }) => {
    const dispatch = useDispatch();

    const isAuth: boolean = useSelector(isAuthSelector);
    const currentCartValue = useSelector(productCartValueSelector(id));
    const [currentQuantity, setCurrentQuantity] = useState<number>(0);

    useEffect(() => {
        if(currentCartValue) {
            setCurrentQuantity(currentCartValue || 0);
        }
    }, [currentCartValue])

    const PriceWidget = () => {
        return <Text>{unitPrice || 0}/{unitLabel}</Text>;
    }


    const addItem = () => {
        if (currentQuantity && currentQuantity > 0 && active) {
            dispatch(addProductCart({ productId: id, quantity: currentQuantity, price: unitPrice, unit: unitLabel }))
        }
    }

    const removeItem = () => {
        dispatch((removeProductCart({productId: id, cartId: ''})));
        setCurrentQuantity(0);
    }

    const onEdit = () => {
        dispatch(intiatedEditProduct({product: {title, description, image, id, unitPrice, unitLabel, active, productCode}}))
    }

    const TitleComp = (title: string) => {
        return <div>
            <Col>
            <Row justify={'space-between'}>
                {title}
                {isAuth&&<EditOutlined style={{fontSize: 20}} onClick={onEdit} />}
            </Row>
            {!active&&<div style={{fontSize: 15, color: 'red'}}>Product is in-active.</div>}
            </Col>
            
        </div>
    }

    return <>
        <Card cover={
            <img
                alt={title}
                src={image}
                height={250}
            />
        }
            actions={[
                <PriceWidget />,
                isAuth&&<Space >
                    <InputNumber min={0} step={0.1} value={currentQuantity} disabled={!active}
                    onChange={(value: number | null) => setCurrentQuantity(value || 0)} />
                    {currentCartValue&&<Tooltip title="Remove item"><DeleteOutlined onClick={removeItem} style={{color: 'red', fontSize: 20}}/></Tooltip>}
                </Space>
                ,
                isAuth&&<>
                    <Button disabled={!currentQuantity||!active} onClick={addItem}> Add </Button>
                </>,
            ]}
        >

            <Card.Meta title={TitleComp(title)} description={description} />


        </Card>
    </>
}