import React, { FC, useEffect } from 'react';
import { Layout, Space, ConfigProvider } from 'antd';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { BrowserRouter } from 'react-router-dom'

import loggedInRouter from '../../router/loggedInRouter';
import authRouter from '../../router/authRouter';
import { initApp } from '../../state/app/actions';
import { appInProgressSelector, isAuthSelector } from '../../state/app/selectors';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../consts/colors';
import NavigationHandler from '../../components/navigationHandler';
import Loader from '../../components/loader';
const { Content } = Layout;

const Intial: FC = () => {
    const dispatch = useDispatch();
    const inProgress = useSelector(appInProgressSelector);
    const isAuth = useSelector(isAuthSelector);

    useEffect(() => {
        dispatch(initApp());
    }, [])


    return <>
        <BrowserRouter>
        <ConfigProvider theme={{
                token: {
                    colorPrimary: colors.primary
                    // colorFillSecondary: '#F72B10'
                },
            }}>
            <Space direction="vertical" style={{ width: '100%', }} size={[0, 48]}>
                <Layout style={{ height: "100vh" }}>
                    <Header />
                    <NavigationHandler/>
                    <Content>
                        {
                                inProgress ? <Loader/> :
                                    isAuth ? loggedInRouter : authRouter
                            }
                    <Footer/>
                    </Content>
                    
                    </Layout>
                </Space>
            </ConfigProvider>
        </BrowserRouter>
    </>
}

export default Intial;