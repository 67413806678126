import { FC, RefObject, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoice } from "../../state/invoice/actions";
import { useParams, useLocation } from "react-router-dom";
import { invoiceDetailsSelector, invoiceInProgress } from '../../state/invoice/selectors';
import { Empty, Typography, Row, Col, Button } from "antd";
import { round } from "lodash";
import './bill.styles.css'
import { FilePdfOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import Loader from "../../components/loader";

const { Title, Paragraph, Text } = Typography;

export const Bill: FC = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const {autoPrint} = state||{};

    const dispatch = useDispatch();

    const billRef: RefObject<HTMLDivElement> = useRef(null);

    const invoiceDetails = useSelector(invoiceDetailsSelector);
    const inProgress = useSelector(invoiceInProgress);

    useEffect(() => {
        if (id) {
            dispatch(getInvoice({ invoiceId: id }))
        }
    }, [])


    useEffect(() => {
        if(invoiceDetails && invoiceDetails.invoiceDetail && autoPrint) {
            print();
        }
    }, [invoiceDetails])

    const print = useReactToPrint({
        content: () => billRef.current
    })

    const receiptComponent = () => {
        return <div ref={billRef} style={{ padding: 10 }}>
            <Text className='centered-element'>Receipt</Text>
            <Text className='centered-element' style={{ fontWeight: 'bolder', fontSize: 22 }}>Mana Inti Ruchulu</Text>
            <Text className='centered-element' style={{ fontWeight: 'bolder', fontSize: 22 }}>(Laxmi's Home Food's)</Text>
            <hr className="dashed" />
            <Text className='centered-element' strong>{moment(invoiceDetails?.invoiceDetail.createdAt).format('MMM-DD-YY hh:mm:ss')}</Text>
            <Text strong>Bill no: {id}</Text>
            <hr className="dashed" />
            <Row>
                <Col xs={12}>
                    Item quantity
                </Col>

                <Col xs={4}>
                    Rate
                </Col>
                <Col xs={4}>
                    Discount (₹)
                </Col>
                <Col xs={4}>
                    Amt.
                </Col>
            </Row>
            <hr className="dashed" />

            {invoiceDetails?.invoiceItems.map((data, index) => {
                return <div key={`${index}-d`}>
                    <Row>
                        <Col xs={12}>
                            {data.title}  {data.quantity} {data.unitLabel}
                        </Col>
                        <Col xs={4}>
                            {data.unitPrice}/{data.unitLabel}
                        </Col>
                        <Col xs={4}>
                            {round(data.quantity * data.discountAmount)}
                        </Col>
                        <Col xs={4}>
                            {data.quantity * (data.unitPrice - data.discountAmount)}
                        </Col>
                    </Row>
                </div>
            })}

            <hr className="dashed" />
            <Row>
                <Col xs={18}>subtotal</Col>
                <Col xs={6}>{invoiceDetails?.invoiceDetail.subtotal}</Col>
            </Row>
            <Row style={{ paddingBottom: 10 }}>
                <Col xs={18}>Discount</Col>
                <Col xs={6}>{invoiceDetails?.invoiceDetail.discountAmount}</Col>
            </Row>

            <Row>
                <Col xs={18}>Total</Col>
                <Col xs={6}>{invoiceDetails?.invoiceDetail.totalAmount}</Col>
            </Row>
            <hr className="dashed" />
            <Row>
                <Col xs={18}>
                    <Text strong>Total Items:</Text>
                </Col>
                <Col xs={6}>
                    <Text strong>
                        {invoiceDetails?.invoiceItems.length}
                    </Text>
                </Col>
            </Row>
            <hr className="dashed" />
            <Text style={{ display: 'flex' }} strong>Contact: 8985855018</Text>
            <hr className="dashed" />

        </div>
    }

    return <>
    {inProgress && <Loader/>
    
    }
        {
            
            invoiceDetails ? <>
                <Row justify={'end'}>
                    <div style={{padding: 10}}>
                        <Button onClick={() => print()}>Print <FilePdfOutlined/></Button>
                    </div>
                </Row>
                <Row>
                    <Col xs={24} xl={6}>
                        <div style={{ border: '1px solid grey', borderRadius: 5, paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, marginLeft: 5, marginTop: 10 }}>
                            <Row>
                                <Col span={12}>
                                    Customer Name:
                                </Col>
                                <Col span={6}>
                                    <Paragraph>

                                        {invoiceDetails.invoiceDetail.customerName}
                                    </Paragraph>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    Payment Mode:
                                </Col>
                                <Col span={6}>
                                    <Paragraph>

                                        {invoiceDetails.invoiceDetail.modeOfPayment}
                                    </Paragraph>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>


                                    Contact Number:

                                </Col>
                                <Col span={6}>
                                    <Paragraph>
                                        {invoiceDetails.invoiceDetail.contact}
                                    </Paragraph>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    Remarks:
                                </Col>
                                <Col span={6}>
                                    <Paragraph>

                                        {invoiceDetails.invoiceDetail.remarks}
                                    </Paragraph>

                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={24} xl={18}>
                        {receiptComponent()}
                    </Col>
                </Row>

            </> : <Empty />
        }
    </>
}