import { combineReducers } from "@reduxjs/toolkit";
import { barcodeScannerReducer } from 'react-usb-barcode-scanner';
import {IProducts} from './products/initialState';
import {IApp} from './app/initialState';
import {IAuth} from './auth/initialState';
import { IinvoiceState } from "./invoice/initialState";
import {IPreOrdersState} from './preOrder/initialState'

import productsReducer from './products/reducer';
import appReducer from "./app/reducer";
import authReducer from './auth/reducer';
import invoiceReducer from "./invoice/reducer";
import preOrdersReducer from './preOrder/reducer';

export interface Store  {
    products: IProducts
    app: IApp
    auth: IAuth,
    invoice: IinvoiceState,
    preOrder: IPreOrdersState,
}


const rootReducers = combineReducers({
    products: productsReducer,
    app: appReducer,
    auth: authReducer,
    invoice: invoiceReducer,
    preOrder: preOrdersReducer,
    barcodeScanner: barcodeScannerReducer
})

export default rootReducers;