import React, { FC, useState } from 'react';
import { Layout, Row, Col, Space, Badge, Avatar, Image, Menu } from 'antd';
import type { MenuProps } from 'antd';

import { useSelector } from 'react-redux';
import { inCartProductsCount } from '../../state/products/selector'
import {
    ShoppingOutlined,
    HomeOutlined,
    LoginOutlined,
    ContainerOutlined,
    BookOutlined,
    PaperClipOutlined
} from '@ant-design/icons';
import { routes } from '../../router/routes';
import { useNavigate, useLocation } from "react-router-dom";
import { isAuthSelector } from '../../state/app/selectors';
import colors from '../../consts/colors';
import styles from '../../consts/styles';


const { Header: AntHeader } = Layout;


const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: colors.white
};

const Header: FC = () => {

    let navigate = useNavigate();
    const inCartCount = useSelector(inCartProductsCount);
    const isAuth: boolean = useSelector(isAuthSelector);
    const location = useLocation();


    const items: MenuProps['items'] = [
        {
            label: 'Invoices',
            key: 'invoice',
            icon: <ContainerOutlined style={styles.iconPrimary}/>,
        },
        {
          label: 'Cart',
          key: 'cart',
          icon: <span>
                <ShoppingOutlined style={styles.iconPrimary} />
                {inCartCount > 0 && <div style={{color: colors.primary, top: 18, right: 10, position: 'absolute', fontWeight: 'bolder'}}>{inCartCount}</div>}
          </span>
        },
        {
            label: 'Pre-orders',
            key: 'pre-orders',
            icon: <PaperClipOutlined style={styles.iconPrimary} />
        }
        
        // {
        //   label: 'Expenses',
        //   key: 'expenses',
        //   icon: <BookOutlined style={styles.iconPrimary}/>,
        // }
    ]

    const handleMenuItemClick: MenuProps['onClick'] = (e) => {
        switch(e.key) {
            case 'invoice':
                return navigate(routes.INVOICES)
            case 'cart':
                return navigate(routes.CART)
            case 'expenses':
                return navigate(routes.CART)
            case 'pre-orders':
                return navigate(routes.PRE_ORDERS)
        }
       
    };
    
    
    return <AntHeader style={headerStyle}>
        <Row justify={'space-between'}>
            <Col span={2}>
                {
                    !isAuth && location.pathname === '/' ? <LoginOutlined style={styles.iconPrimary} onClick={() => navigate(routes.LOGIN)} />
                        :
                        <HomeOutlined style={{...styles.iconPrimary, fontSize: 20}} onClick={() => navigate(routes.HOME)} />
                }

            </Col>


            <Col span={6} offset={6}>
                <Image src={'main_logo.png'} height={50} preview={false} onClick={() => navigate(routes.HOME)} />
            </Col>
            <Col offset={4} span={6}>

            {isAuth && <Menu items={items}  mode="horizontal" onClick={handleMenuItemClick} /> }
                {/* {inCartCount > 0 && isAuth &&
                    <Space size="small" direction="vertical">
                        <Badge count={inCartCount} color={colors.secondary} >
                            <Avatar style={{ backgroundColor: 'white' }} icon={<ShoppingOutlined style={styles.iconPrimary} />} onClick={() => navigate(routes.CART)} />
                        </Badge>
                    </Space>
                } */}
            </Col>
            {/* <Col span={1} offset={2}>
                {isAuth && <Avatar style={{ backgroundColor: 'transparent' }} icon={<ContainerOutlined style={{ ...styles.iconPrimary }} />} onClick={() => navigate(routes.INVOICES)} />}
            </Col> */}
        </Row>
    </AntHeader>
}

export default Header;