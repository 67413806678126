import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../rootReducer'
import { initialState } from './initialState';

const appStateSelector = (state: Store) => state.auth || initialState;


export const authInProgressSelector = createSelector(
    appStateSelector,
    appState => appState.inProgress
);
