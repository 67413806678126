import React, { FC } from 'react';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import { login } from '../../state/auth/actions';
import {authInProgressSelector} from '../../state/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import './login.styles.css';

export const Login: FC = () => {
    const dispatch = useDispatch();
    const inProgress: boolean = useSelector(authInProgressSelector);

    const onFinish = ({email, password}: {email: string, password: string}) => {
        dispatch(login({email, password}))
    }
    const onFinishFailed = () => {
        
    }
    return <>
        <div className='center'>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}


                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'not a valid email' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>


                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" loading={inProgress}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </>
}