import { all, call, takeEvery, put, take, fork, select } from 'redux-saga/effects';
import {
    ADD_PRODUCT_CART, addProductCartSuccess, PRODUCTS_REQUEST, productsRequestComplete,
    SAVE_PRODUCT, saveProductComplete, cancelSaveProduct, ADD_PRODUCT_TO_CART_FROM_BARCODE, addProductCart,
    USB_BARCODE_SCANNED
} from './actions'
import { getFirestore, collection, getDocs, doc, updateDoc, setDoc, getDoc, query, where, orderBy, limit } from 'firebase/firestore';
import { IProductItem } from './initialState';
import { decodeBarcode, uploadFile } from '../utils';
import {isAuthSelector} from '../app/selectors';
import { PayloadAction } from '@reduxjs/toolkit';

const getProductsData = (isAuth: boolean = false) => new Promise(async (resolve, reject) => {

    try {
        const db = getFirestore();

        const productsRef = collection(db, "products")

        let condtion = isAuth ? orderBy("active", 'desc') : where("active", "==", true)
        
        const q = query(productsRef, condtion, orderBy("title", 'asc'))
        
        const querySnapShot = await getDocs(q);

        const data: Array<IProductItem> = []
        querySnapShot.forEach((doc) => {
            data.push((doc.data() as IProductItem));
        })
        return resolve(data);
    } catch (error) {
        console.error(error)
        return resolve([])
    }
    // const data = querySnapShot.map((doc) => doc.data())
})


const getProductByCode = (productCode: number) => new Promise(async (resolve) => {
    try {
        const db = getFirestore();

        const productsRef = collection(db, "products")
        

        const q = query(productsRef, where("productCode", "==" ,productCode), limit(1))
        const querySnapShot = await getDocs(q);

        const data: Array<IProductItem> = []
        querySnapShot.forEach((doc) => {
            data.push((doc.data() as IProductItem));
        })
        if (data.length > 0) {
            return resolve(data[0])
        }
        return resolve(undefined)
        
    } catch (error) {
        console.error(error)
        return resolve(undefined)
    }
})


function* getAllProducts() {
    const isAuth: boolean = yield select(isAuthSelector);
    const data: Array<IProductItem> = yield call(getProductsData, isAuth);
    yield put(productsRequestComplete(data))
}


const updateProduct = async (data: any, id: string) => {
    const db = getFirestore();
    const itemRef = doc(db, "products", id);
    await updateDoc(itemRef, { ...data })
}

const createProduct = async (data: any) => {
    const db = getFirestore();

    const itemRef = doc(collection(db, "products"));
    await setDoc(itemRef, { ...data, id: itemRef.id });
    return (await getDoc(itemRef)).data();
}

function* saveProductData(action: any) {
    try {
        const { title, description, id, unitLabel, unitPrice, image, active, productCode } = action.payload.product;

        let imageUrl = null;
        if (image && Array.isArray(image) && image.length > 0) {
            // this means its a already uploaded file.
            if (image[0].url) {
                imageUrl = image[0].url;
            }
            // this file needs to be uploaded
            else if (image[0].originFileObj) {
                const data: object = yield call(uploadFile, `images/${id}`, image[0].originFileObj);
                imageUrl = data;
            }
        }

        if (id === '__NEW__') {
            const createdDoc: IProductItem = yield call(createProduct, { title, description, unitLabel, unitPrice, image: imageUrl, active });

            yield put(saveProductComplete({ product: createdDoc }))
            return
        }

        // TODO: update this specific product and or add this
        yield call(updateProduct, { title, description, unitLabel, unitPrice, image: imageUrl, active, productCode }, id);
        yield put(saveProductComplete({ product: { title, description, unitLabel, unitPrice, image: imageUrl, active, id: id, productCode } }))
    } catch (error) {
        yield put(cancelSaveProduct());
    }


}

function* usbBarCodeScanned(action: any){
    let {data: barcode} = action.payload
    // const {barcode} = {barcode: '1002001525'};
    try {
        barcode = barcode.trim()
        console.log({barcode})
        if (!barcode) return;
        const barcodeData  = decodeBarcode(barcode)
        if (!barcodeData) return;
        console.log({barcodeData});
        
        const {productCode,  quantity} = barcodeData;
        const product: IProductItem | undefined  = yield call(() => getProductByCode(productCode))
        if (!product) return;

        const {id, unitPrice, unitLabel} = product
        const currentQuantity = quantity/1000
        
        yield put(addProductCart({ productId: id, quantity: currentQuantity, price: unitPrice, unit: unitLabel }))
        const audio = new Audio('beep.mp3')
        audio.play()
    } catch (error) {
        console.log(error);
        
    }
}

function* handleAddProductToCartFromBarcode(action: any){
    try {
        
        const {barcode} = action.payload;
        console.log({barcode})
        if (!barcode) return;
        const barcodeData  = decodeBarcode(barcode)
        if (!barcodeData) return;

        const {productCode,  quantity} = barcodeData;
        const product: IProductItem | undefined  = yield call(() => getProductByCode(productCode))
        if (!product) return;

        const {id, unitPrice, unitLabel} = product
        const currentQuantity = quantity/1000
        
        yield put(addProductCart({ productId: id, quantity: currentQuantity, price: unitPrice, unit: unitLabel }))
        const audio = new Audio('beep.mp3')
        audio.play()
    } catch (error) {
        console.error(error)
    }
}

export default function* () {
    yield all([
        takeEvery(PRODUCTS_REQUEST, getAllProducts),
        takeEvery(SAVE_PRODUCT, saveProductData),
        takeEvery(ADD_PRODUCT_TO_CART_FROM_BARCODE, handleAddProductToCartFromBarcode),
        takeEvery(USB_BARCODE_SCANNED, usbBarCodeScanned)
    ])
}