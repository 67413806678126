import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";

export const uploadFile = (path: string, file: File) => new Promise((resolve, reject) => {
    try {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        uploadBytes(storageRef, file).then((snapshot) => {
            const downloadUrl = getDownloadURL(snapshot.ref)
            return resolve(downloadUrl);
        })
    } catch (error) {
        console.error(error)
        return resolve(null)
    }
})


// Barcode generated by machine is 10 digits 
// First five digits identify the product
// last five identify Quantity in Grams or Pieces (**NOTE** for now consider quantity in Grams)
// example: 1002001525 is the barcode  product code is '10020' and '01525' Grams or (1.525Kgs)
export const decodeBarcode = (barcode: string): {productCode: number, quantity: number} | undefined =>  {

    if (barcode.length < 10 || barcode.length > 10) return

    const productCode =  Number(barcode.substring(0, 5));
    const quantity = parseInt(barcode.substring(5), 10)
    return {productCode, quantity}
}