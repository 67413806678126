import { createAction } from '@reduxjs/toolkit'
import {IinvoiceItem, IInvoiceProductItem} from './initialState'
import {Dayjs} from 'dayjs';

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const createInvoice = createAction(CREATE_INVOICE);

export const CREATE_INVOICE_COMPLETE = 'CREATE_INVOICE_COMPLETE'
export const createInvoiceComplete = createAction(CREATE_INVOICE_COMPLETE);

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST'
export const getInvoicesRequest = createAction(GET_INVOICES_REQUEST);

export const GET_INVOICES_COMPLETE = 'GET_INVOICES_COMPLETE';
export const getInvoicesComplete = createAction<{invoices: IinvoiceItem[], count: number, lastVisible?: any}>(GET_INVOICES_COMPLETE);

export const GET_INVOICE = 'GET_INVOICE';
export const getInvoice = createAction<{invoiceId: String}>(GET_INVOICE);

export const GET_INVOICE_COMPLETE = 'GET_INVOICE_COMPLETE';
export const getInvoiceComplete = createAction<{invoiceItems: IInvoiceProductItem[], invoiceDetail?: IinvoiceItem}>(GET_INVOICE_COMPLETE);


export const DATE_RANGE_CHANGE = 'DATE_RANGE_CHANGE';
export const dateRangeChange = createAction<[Dayjs, Dayjs]>(DATE_RANGE_CHANGE)