import { useZxing } from "react-zxing";
import { useMediaDevices } from "react-media-devices";


interface IProps {
  onBarcodeDecoded: (barcode: string) => void
}
export const BarcodeReader = (props: IProps) => {
  const constraints: MediaStreamConstraints = {
    video: true,
    audio: false,
  };

  const { devices } = useMediaDevices({ constraints });
  const getDevice = (devices: MediaDeviceInfo[]) => {
    if (!devices.length) return ''

    const backCamera = devices?.find(device => device.kind === 'videoinput' && device.label.includes('back'))
    if (backCamera) {
      return backCamera.deviceId
    }

    const frontCamera = devices?.find(device => device.kind === 'videoinput')
    if (frontCamera) {
      return frontCamera.deviceId
    } else {
      return ''
    }
  }
  const deviceId = getDevice(devices || [])

  const { ref } = useZxing({
    paused: !deviceId,
    deviceId,
    onDecodeResult(result) {
      console.log(result.getText());
      props.onBarcodeDecoded(result.getText())
      //   setResult(result.getText());
    },
  });

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "90%", height: "50%" }}>
        <video width={"90%"} height={"50%"} ref={ref} />
      </div>
    </div>
  );
};
