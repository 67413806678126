import { Routes, Route, Navigate } from 'react-router-dom';
import { routes } from './routes';
import Products from '../pages/products';
import Login from '../pages/login';
import HomeScreen from '../pages/home';

export default (
    <Routes>
        <Route path={'/'} element={<HomeScreen/>}/>
        <Route path={routes.LOGIN} element={<Login/>}/>
        <Route path='/*' element={<Products/>}/>
    </Routes>);